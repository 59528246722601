<template>
  <div>
    <div :style="calcMaxHeight()">
      <b-card bg-variant="primary" no-body style="height: 100%; color: green;">
        <b-card-header header-bg-variant="primary" header-text-variant="dark" class="p-1">
          <i class="fa fa-random"></i>
          Ağaç Listesi Tanımlamaları
        </b-card-header>
        <tree-list :show_mode="show_mode" :tree_list_type="'clinical_branch'" :select_type="select_type" :mode="mode" :selected_items_data_list="selected_items_data_list"></tree-list>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  default as Modal
} from '@/components/widgets/Modal';
import {
  default as TreeList
} from '@/components/widgets/TreeList';

export default {
  name: 'ClinicalBranchList',
  components: {
    Modal,
    TreeList
  },
  computed: {
    ...mapGetters({
      patient: 'patient_selected',
      device: 'device'
    })
  },
  data () {
    return {
      show_mode: 'treeView',
      mode: 'edit', // ['edit', 'use']
      select_type: 'one', // ['one', 'many']
      selected_items_data_list: [],
      show_tree_data_settings: false
    }
  },
  created: function () {},
  mounted: function () {},
  methods: {
    calcMaxHeight: function () {
      return 'height: ' + (window.innerHeight - 85).toString() + 'px';
    }
  },
  watch: {}
}

</script>

<style type="text/css">


</style>

